
.half {
}


.appicons {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row nowrap;
}
