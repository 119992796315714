.categoryContainer {
  display: flex;
  color: white;
  flex-flow: column nowrap;
  width: 100%;
}

.categoryInner {
  display: flex;
  flex-flow: column nowrap;

  border-radius: 25px;
  border: 0.5px solid #d3d3d3;
  padding: 16px;
  margin-bottom: 16px;
  color: black;

  box-shadow: 1px 1px 5px #b5b5b5;
  overflow: visible;
  margin-left: 5px;
  width: calc(100% - 10px);

  &:first-child {
    margin-top: 8px;
  }

  img {
    border-radius: 8px;
  }
}



.categoryContainer p {
  display: flex;
  justify-items: center;
  justify-content: center;

  margin: 0 auto;
  width: 100%;
  padding-top: 0.5em;
  font-size: 1.2em;
}

.role {
  float: right;
  align-items: center;
  display: flex;
}
