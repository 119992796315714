/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    /** primary **/
    /*--ion-color-primary: #3880ff;*/
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    /*--ion-color-primary-shade: #3171e0;*/
    --ion-color-primary-tint: #4c8dff;

    --ion-color-primary: #b9b9b9;
    --ion-color-primary-shade: #b9b9b9;
    --ion-color-primary-tint: #8d8d8d;


    /** secondary **/
    --ion-color-secondary: #3dc2ff;
    --ion-color-secondary-rgb: 61, 194, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #36abe0;
    --ion-color-secondary-tint: #50c8ff;

    /** tertiary **/
    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    /** success **/
    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /* CUSTOM */
    --ion-background-color: white;
    --ion-tab-bar-background: white;
    --ion-tab-bar-color: rgb(219, 219, 219);
    --ion-tab-bar-color-selected: rgb(85, 85, 85);


}

ion-tab-bar {

    --border: none;
    height: 3.5rem;
}

ion-toolbar,
ion-header {

    --background: white;
    --border-color: #F4F5F8;
}

ion-tab-bar.floating {

    --background: white;
    box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.2);
    border-radius: 20px !important;

    height: 50px;
    width: 90%;
    padding-top: 5px;
    padding-bottom: 5px;

    bottom: 20px;
    position: relative;
    margin: 0 auto !important;
    border-top: none;
}

ion-tab-button {

    border-radius: 16px !important;
}

ion-tab-button ion-icon {

    font-size: 1.75rem;
}

.custom-back {

    --color: rgb(99, 99, 99);
}


.page-title {

    text-transform: uppercase;
}

.tab-dot {

    border-radius: 500px;
    background-color: var(--ion-tab-bar-color-selected);
    height: 5px;
    width: 5px;
    margin-top: 1.5rem;
    position: absolute;
    z-index: 999;
}

.cart-count {

    position: absolute;
    background-color: rgb(42, 42, 42);
    color: white;
    border-radius: 500px;
    padding: 5px;
    width: 20px;
    height: 20px;
    font-size: 0.8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 2px;
    margin-left: 2px;
}


.w100vw {
    width: 100vw;
}

.w100 {
    width: 100%;
}

.h100vh {
    height: 100vh;
}

.h100 {
    height: 100%;
}

.ratio-11 {
    aspect-ratio: 1 / 1;
}

.ratio-inherit {
    aspect-ratio: inherit;
}

.ll-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-5 {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-6 {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-7 {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ll-8 {
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.padlr10px {
    padding-left: 10px;
    padding-right: 10px;
}

.padlr16px {
    padding-left: 16px;
    padding-right: 16px;
}

.padlr20px {
    padding-left: 20px;
    padding-right: 20px;
}

.padlr30px {
    padding-left: 30px;
    padding-right: 30px;
}

.padlr40px {
    padding-left: 40px;
    padding-right: 40px;
}

.pad-0 {
    padding: 0;
}

.margin-0 {
    margin: 0;
}

/*.button-solid {*/
/*  --background: #b9b9b9;*/
/*}*/


.action-sheet-wrapper.sc-ion-action-sheet-ios {
    z-index:900;
    transform: inherit;
}



.wrap {
    display: flex;
    width: 100%;
    flex-grow: 1;
    position: relative;
    height: 100vh;
}

.dropzone {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.placeholder {
    width: 100%;
    max-width: 500px;
    border-radius: 0.5em;
    background: #252525;
    padding: 2em;
    text-align: center;
}

.placeholder p {
    font-size: 1.2rem;
    color: #999;
}

.viewer {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    position: absolute;
    top: 0;
    z-index: 0;
}

.axes {
    width: 100px;
    height: 100px;
    margin: 20px;
    padding: 0px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 10;
    pointer-events: none;
}

/******************************************************************************
 * Header
 */

header {
    display: flex;
    background: #353535;
    padding: 0 2em;
    height: 4rem;
    line-height: 4rem;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.3);
    z-index: 1;

    -webkit-app-region: drag;
}

header h1,
header .item,
header .separator {
    color: #F5F5F5;
    font-weight: 300;
    line-height: 4rem;
    margin: 0;
}

header h1 {
    font-size: 1.4rem;
}

header h1 > a {
    color: inherit;
    font-size: inherit;
    text-decoration: inherit;
}

header .item {
    padding: 0 1em;
    font-size: 0.8rem;
    text-decoration: none;
    transition: background ease 0.2s;

    -webkit-app-region: no-drag;
}

header .item:hover {
    background: #444;
}

header button.item {
    height: 34px;
    line-height: 35px;
    padding: 0 1em;
    border: 0;
    background: #ffc107;
    color: #333;
    font-weight: 500;
    border-radius: 2px;
    cursor: pointer;
}

header button.item:hover {
    color: #000;
}

header .separator {
    margin: 0 0.2em;
    opacity: 0.2;
}

header h1 + .separator {
    margin-left: 1em;
}

.flex-grow {
    flex-grow: 1;
}

.gui-wrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.gui-wrap > .main {
    pointer-events: all;
    max-height: 100%;
    overflow: auto;
}

.dg li.gui-stats:not(.folder) {
    height: auto;
}

@media screen and (max-width: 700px) {
    header h1 {
        font-size: 1em;
    }

    .layout-md {
        display: none;
    }
}

/******************************************************************************
 * Upload Button
 *
 * https://tympanus.net/Tutorials/CustomFileInputs/
 */

.upload-btn {
    margin-top: 2em;
}

.upload-btn input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.upload-btn label {
    color: #808080;
    border: 0;
    border-radius: 3px;
    transition: ease 0.2s background;
    font-size: 1rem;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 0.625rem 1.25rem;
}

.upload-btn label:hover {
    background: #252525;
}

.upload-btn svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
}


/******************************************************************************
 * Validation report
 */

.report {
    padding: 2em;
    max-width: 860px;
}

.report h1 {
    margin-top: 0;
}

.report p,
.report ul {
    line-height: 1.5em;
}

.report-table {
    text-align: left;
    border-collapse: collapse;
    width: 100%;
}

.report-table thead tr {
    background: #404040;
    color: #FFF;
}

.report-table th,
.report-table td {
    padding: 0.5em 1em;
}

.report-table tr:nth-child(2n) {
    background: #F0F0F0;
}

/******************************************************************************
 * Validation toggle
 */

.report-toggle-wrap.hidden { display: none; }

.report-toggle {
    cursor: pointer;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 20px;
    height: 30px;
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.25);
    background: #FFF;
    box-sizing: border-box;

    color: #f0f0f0;
    background: #000;
    border-left: 6px solid #000;
}

.report-toggle.level-1 { color: #444; background: #ffeda0; border-left-color: #feb24c; }
.report-toggle.level-0 { color: #444; background: #f4c2be; border-left-color: #b10026; }

.report-toggle-text {
    line-height: 32px;
    padding: 0 0.5em;
    font-weight: 300;
    font-size: 0.8em;
}

.report-toggle-close {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1.5em;
    text-align: center;
}

/******************************************************************************
 * CSS Spinner
 *
 * http://tobiasahlin.com/spinkit/
 */

.spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px;

    background-color: #333;

    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
          opacity: 0;
      }
}





/******************************************************************************
 * Pace
 */
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

/*.pace .pace-progress {*/
/*    background: #fcd25a;*/
/*    position: fixed;*/
/*    z-index: 2000;*/
/*    top: 0;*/
/*    right: 100%;*/
/*    width: 100%;*/
/*    height: 2px;*/
/*}*/

/*.pace .pace-progress-inner {*/
/*    display: block;*/
/*    position: absolute;*/
/*    right: 0px;*/
/*    width: 100px;*/
/*    height: 100%;*/
/*    box-shadow: 0 0 10px #fcd25a, 0 0 5px #fcd25a;*/
/*    opacity: 1.0;*/
/*    -webkit-transform: rotate(3deg) translate(0px, -4px);*/
/*    -moz-transform: rotate(3deg) translate(0px, -4px);*/
/*    -ms-transform: rotate(3deg) translate(0px, -4px);*/
/*    -o-transform: rotate(3deg) translate(0px, -4px);*/
/*    transform: rotate(3deg) translate(0px, -4px);*/
/*}*/

.pace .pace-activity {
    display: block;
    position: fixed;
    z-index: 2000;
    top: 15px;
    right: 15px;
    width: 14px;
    height: 14px;
    border: solid 2px transparent;
    border-top-color: #fcd25a;
    border-left-color: #fcd25a;
    border-radius: 10px;
    -webkit-animation: pace-spinner 400ms linear infinite;
    -moz-animation: pace-spinner 400ms linear infinite;
    -ms-animation: pace-spinner 400ms linear infinite;
    -o-animation: pace-spinner 400ms linear infinite;
    animation: pace-spinner 400ms linear infinite;
}

@-webkit-keyframes pace-spinner {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes pace-spinner {
    0% { -moz-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes pace-spinner {
    0% { -o-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes pace-spinner {
    0% { -ms-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes pace-spinner {
    0% { transform: rotate(0deg); transform: rotate(0deg); }
    100% { transform: rotate(360deg); transform: rotate(360deg); }
}



.flex {
    display: flex;
    align-items: center;
}
.flex-1 {
    flex: 1;
}
.border {
    border: 1px solid #999;
}
.p-5 {
    padding: 1.25rem;
}

.px-5 {
    padding: 0 1.25rem;
}
.bttn {
    min-width: 0;
    padding: 0 1rem;
    font-size: 20px;
}
