

.container {
  margin-top: 16px;
  width: 100%;
  border: 1px solid black;
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;

  :global(.r) {
  }
}

.currentBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  width:100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;


  :global(.r1) {
    align-self: flex-start;
  }
  :global(.r2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;


    :global(.w20) {
      width: 50px;
      height: 50px;
    }
  }
}

.usedBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  width:100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;

  :global(.l) {
  }
  :global(.r) {
    flex: 1 1;
    text-align: right;
  }
}

.earnBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  width:100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;

  :global(.l) {
  }
  :global(.r) {
    flex: 1 1;
    text-align: right;
  }
}

.buyBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  width:100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 8px;
  margin-bottom: 8px;

  :global(.l) {
  }
  :global(.r) {
    flex: 1 1;
    text-align: right;
  }
}

.line {
  width: 100%;
  border-top: 1px solid black;
  //padding-top: 10px;
  //padding-bottom: 5px;
  height: 1px;
  display: block;
  //margin-left: 10px;
  //margin-right: 10px;

  margin-top: 8px;
  margin-bottom: 8px;
}

:global(.lm20) {
    margin-left: 20px;
}
:global(.lm8) {
  margin-left: 8px;
}
