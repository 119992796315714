
.readerContainer {
  position: relative;
}

.reader {
  z-index: 9999;
  background-color: white;
  border: 5px solid red;
}
.readerBg {
  //position: absolute;
  //top: 50%;
  //text-align: center;
  //width: 100%;
  //z-index: 1;
  display: inline-block;
  text-align: center;
  width: 100%;
}

.readerBgText {
  max-width: 350px;
  display: inline-block;
}
