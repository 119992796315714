.listItem {

}


.listTitle {
  width: 100%;

  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;

  :global(.l) {
    display: inline;
  }

  :global(.r) {
    min-width: 110px;
    padding-left: 8px;
    box-sizing: border-box;
  }
}
