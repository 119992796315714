@import '../pages/RWD';

.themeBorderBottom {
    border-bottom: 2px solid rgb(255, 202, 34) !important;
}


.themeBorderTop {
    border-top: 2px solid rgb(255, 202, 34) !important;
}


.logo {
    height: 25px;
}

.abs {

}

.actionButton {
    --padding-start: 0;
    --padding-end: 0;
    height: auto;
}

.actionButtonIcon {
    display: inline-block;
    //color:red;
    width: 30px;
    height: 30px;
}

.userNameBlueIcon {
    display: inline-block;
    color: #39B6FF;
    width: 1em;
    height: 1em;
    margin-left: 5px;
}

.defaultAvatarBgColor {
    fill: #a2a2a2;
    color: #a2a2a2;
    width: 100%;
    height: 100%;
}


.ionContent {
    //@include media("<tablet") { /* is phone */
    //}
    //
    //@include media(">=tablet", "<desktop") { /* is tablet */
    //}
    //
    //@include media(">=desktop", "<xdesktop") { /* is desktop */
    //    --offset-top: 0;
    //    --offset-bottom: 0;
    //    overflow-y: scroll;
    //}
    //
    //@include media(">=xdesktop") { /* is xdesktop */
    //    --offset-top: 0;
    //    --offset-bottom: 0;
    //    overflow-y: scroll;
    //}
}
