.buyButton {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;


}

.right {
  position: absolute;
  right: 0;
  display: inline-block;
}
