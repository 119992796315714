.list {
    width: 100vw;

}



.timeBar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;

    :global(.clock) {
        margin-left: 16px;
        margin-right: 4px;
    }
}



.bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
}
