.avatarContainer {
  width: 80px;
  height: 80px;

  line-height: 80px;
  vertical-align: middle;

  border: 1px solid #33333330;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;

  :global(.avatar) {
    display: inline-block;

    color:red;
    width: 50px;
    height: 50px;
  }

  :global(.name) {
    display:inline-block;
  }
}


.avatarWrap {
  text-align: center;
  margin-top: 2em;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}
