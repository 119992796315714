
.viewerframe {
  width:100%;
  height: 50vh;
  border: 0;
  margin: 0;
}


.content {

  :global(iframe) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
