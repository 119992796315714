@import '../pages/RWD';

.productContainer {

  padding: 1rem;
  border: 2px solid rgb(231, 231, 231);
  background-color: white;
  height: 17.5rem;
  border-radius: 10px;
}

.coverImageContainer {
  @include media("<tablet") { /* is phone */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 0.8rem;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    width: 100%;
  }

  @include media(">=tablet", "<desktop") { /* is tablet */

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;

  }

  @include media(">=desktop", "<xdesktop") { /* is desktop */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;
  }

  @include media(">=xdesktop") { /* is xdesktop */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;
  }

}

.coverImg {
  img {
    border-radius: 12px 12px 0 0;

    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    display: block;
  }
}

.stackBadge {
  width: 3em;
  background-color: #808080;
  position:absolute;
  right: 0;
  top: 2em;
  font-size: 12px;
}

.productTitleContainer {

  border-radius: 0 0 12px 12px;
  border-bottom: 1px solid #0000000d;
  border-left: 1px solid #0000000d;
  border-right: 1px solid #0000000d;
  background-color: #80808042;


  :global(.last) {
    position: relative;
    padding-bottom: 10px;

  }

  :global(.lowestPrice) {
    text-align: left;
    margin-left: 10px;
  }

  :global(.line) {
    border-top: 1px solid #8080802b;
    padding-top: 10px;
    padding-bottom: 5px;
    height: 1px;
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }

  :global(.right) {
    position:absolute;
    right: 10px;
    top: 4px;
  }
}
.container {
  text-align: center;
  /*height: 10rem;*/
  //background-color: white;
  //background-position: center center;
  //background-size: contain;
  //background-repeat: no-repeat;

  display: block;
  width: 100%;

  border-radius: 0.8rem;


  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  grid-column: span 1 / auto;

  pointer-events: auto;
  z-index: 2;

  @include media("<tablet") { /* is phone */
  }

  @include media(">=tablet", "<desktop") { /* is tablet */
  }

  @include media(">=desktop", "<xdesktop") { /* is desktop */
  }

  @include media(">=xdesktop") { /* is xdesktop */
  }
}

.productTitle {
  font-size: 1rem;
  letter-spacing: -0.03rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  margin-top: 0px;
  margin-bottom: 0px;
  height: 2.5em;
  line-height: 2.5em;
  vertical-align: middle;
}

.productRating {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.productRating {
  ion-icon {
    margin-top: 0.1rem;
    font-size: 0.9rem;
  }
}
