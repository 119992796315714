@import '../pages/RWD';

.actionlink {
  width: 100%;
}

.container {
  /*height: 10rem;*/
  //background-color: white;
  //background-position: center center;
  //background-size: contain;
  //background-repeat: no-repeat;
  width: 100%;

  border-radius: 0.8rem;

  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  grid-column: span 1 / auto;

  pointer-events: auto;
  z-index: 2;
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;

  :global(.ll) {
    flex: 0 0 50px;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    align-self: flex-start;

    :global(.avatar) {
      width: 30px;
      height: 30px;
    }
  }

  :global(.l) {
    flex: 1 1;

    overflow-wrap: anywhere;
    white-space: pre-wrap;
    word-wrap: normal;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;

    padding-top:10px;
    padding-bottom:10px;

    :global(.dt) {
      font-size: 1em;
      color: #8d8d8d;
    }
  }

  :global(.r) {
    justify-content: flex-end;

    padding-top: 10px;
    padding-bottom: 10px;
    align-self: flex-start;
  }

  @include media("<tablet") { /* is phone */
  }

  @include media(">=tablet", "<desktop") { /* is tablet */
  }

  @include media(">=desktop", "<xdesktop") { /* is desktop */
  }

  @include media(">=xdesktop") { /* is xdesktop */
  }
}

