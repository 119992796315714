@import '../pages/RWD';

.container {
  width: 100%;
}

.containerInner {
  display: flex;
  flex-flow: column nowrap;

  border-radius: 25px;
  border: 0.5px solid #d3d3d3;
  padding: 16px;
  margin-bottom: 16px;
  color: black;

  box-shadow: 1px 1px 5px #b5b5b5;
  overflow: visible;
  margin-left: 5px;
  width: calc(100% - 10px);

  &:first-child {
    margin-top: 8px;
  }
}

.belowImage  {
  margin-top: 20px;
}

.postBar {
  margin-top: 8px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    display: block;
    border-radius: 8px;

    @include media("<tablet") { /* is phone */
    }

    @include media(">=tablet", "<desktop") { /* is tablet */
    }

    @include media(">=desktop", "<xdesktop") { /* is desktop */
      max-height: calc( 50vh );
    }

    @include media(">=xdesktop") { /* is xdesktop */
      max-height: calc( 50vh );
    }
  }

  img {
    margin-bottom: 5px;
  }
  img:last-child {
    margin-bottom: 0;
  }
}

.actionButton {
  --padding-start: 0;
  --padding-end: 0;
}
.actionManageButton {
  --padding-start: 0.5em;
  --padding-end: 0.5em;
  height: 2em;
}
.belowImageBar {
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  cursor: default;
}
.actionBar {
  margin-top: 0.5em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  cursor: default;
}
.actionBarSideRight {
  flex: 1 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row nowrap;
}

.textBar {
  margin-top: 8px;
  font-size: 1.2em;

  overflow-wrap: anywhere;
  white-space: pre-wrap;
  word-wrap: normal;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 6;

  :global(.dt) {
    font-size: 1em;
    color: #8d8d8d;
  }

  :global(a:link) {
    text-decoration: none;
  }

  :global(a:visited) {
    text-decoration: none;
  }

  :global(a:hover) {
    text-decoration: none;
  }

  :global(a:active) {
    text-decoration: none;
  }
}

.nameBar {
  font-size: 1em;

  :global(.avatar) {
    display: inline-block;

    width: 30px;
    height: 30px;
    //width: 40%;
    //height: 40%;
    //max-width: 200px;
    //max-height: 200px;
  }

  :global(.name) {
    display:inline-block;
  }

  :global(.container) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;

    :global(.l) {

    }
    :global(.r) {
      flex: 1;
      margin-left: 8px;

      overflow-wrap: anywhere;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;


    }

    :global(.r2)
    {
      margin-left: 8px;
      overflow-wrap: anywhere;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      padding: 0 8px;
      border: 1px solid #ddd;
      border-radius: 8px;
      max-width: 45%;
      line-height: 2;

      :global(.roundbg) {


      }
    }
  }
}

.coverImageContainer {
  @include media("<tablet") { /* is phone */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 0.8rem;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    width: 100%;
  }

  @include media(">=tablet", "<desktop") { /* is tablet */

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;

  }

  @include media(">=desktop", "<xdesktop") { /* is desktop */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;
  }

  @include media(">=xdesktop") { /* is xdesktop */
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: grid;

    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: dense;

    row-gap: 4.8rem;
  }
}
