ion-card {
  margin: 0;
	/* margin-top: var(--ion-safe-area-top); */
	z-index: -1;

  border-radius: 0px;

  background-size: cover;
	background-position: center;
  background-repeat: no-repeat;
  box-shadow: none;
  aspect-ratio: 1 / 1;
}

@supports not (aspect-ratio: 1 / 1) {
  ion-card::before {
    float: left;
    padding-top: 100%;
    content: '';
  }

  ion-card::after {
    display: block;
    content: '';
    clear: both;
  }
}

ion-card-header {
  position: absolute;
  bottom: 0;
  width: 100%;

  /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%); */
	background: rgba(0, 0, 0, 0.5)
}

ion-card-title,
ion-card-subtitle {
  color: white;
}

ion-card-header ion-card-title {
  
  margin: 0 0 6px 0;
  font-size: 22px;
}

ion-card-header ion-card-subtitle {
  
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
}

ion-card-content {
  height: calc(60px + var(--ion-safe-area-top));
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}

#close-button {
  position: fixed;

  top: max(var(--ion-safe-area-top), 16px);
  right: 8px;
}

#fave-button {
  position: fixed;

  top: max(var(--ion-safe-area-top), 16px);
	left: 8px;
}

#product-view-buttons {

	z-index: 10;
	background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(82, 82, 82, 0.9) 100%) !important;
	position: absolute;
	width: 100%;
	height: 4rem;
}

.sticky-bottom {

  position: fixed;
  bottom: 0;
}