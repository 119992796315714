
.avatarContainer {
  text-align: center;
  margin-top: 2em;



  :global(.avatar) {
    display: inline-block;
    aspect-ratio: 1;

    width: 40%;
    height: 40%;
    max-width: 200px;
    max-height: 200px;
  }

  :global(.name) {
    display:inline-block;
  }
}
