
.select {
  --background: #f3f3f3;
  --padding-bottom: 1rem;
  --padding-top: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  border-radius: 10px;
  margin-top: 0.25rem;
  transition: all 0.2s linear;

  background-color: #f3f3f3;
  //margin-right: 10px;
}


.highlight {
  width: 70%;
  display: inline-block;
}


.hightlightColor {
  background-color: #ffa50040;
}

.sideRight {
  margin-left: 10px;
}

.topBottomMargin {
  margin-top: 1em;
  margin-bottom: 1em;
}

.agreement {
  background-color: #e9e9e9;
  --color: black;

  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;


  overflow-wrap: anywhere;
  white-space: pre-wrap;
  word-wrap: normal;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  //height: 100px;

  :global(.native-textarea) {
    height: 100px;
  }
}
