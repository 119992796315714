.h50px {
  overflow: hidden;
  object-fit: cover;
  height: 50px;
  width: auto;


  :global(img) {
    height: 100%;
    width: 100%;
  }
}
