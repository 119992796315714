.listItem {

}


.listTitle {
  width: 100%;

  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;

  :global(.l) {
    display: inline;
  }
}


.line {
  border-top: 1px solid black;
  //padding-top: 10px;
  //padding-bottom: 5px;
  height: 1px;
  display: block;
  //margin-left: 10px;
  //margin-right: 10px;
}
