.homePage {

    ion-header {

        ion-img {

            border-bottom: 3px solid rgb(236, 149, 35);
        }
    }

    ion-footer {

        background-color: #7c3b6a;
        color: white;
    }
}

.getStarted {

    height: 100%;
    background-color: #ffffff;
    background-image: radial-gradient(#b8b8b8 1px, transparent 1px),
    radial-gradient(#b8b8b8 1px, #ffffff 1px);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;

    ion-card-title {

        color: black !important;
        letter-spacing: -0.08rem;
        font-weight: 900 !important;
    }
}

.heading {

    margin-top: 7rem;
}

.getStartedButton {

    font-size: 1.2rem;
    margin-top: 1rem;
}
