@import '../pages/RWD';

.topImage {
  //display: flex;
  //justify-content: flex-start;
  //align-items: center;
  //flex-flow: row nowrap;

  //display: flex;
  //overflow: hidden;
  //width: 100%;

  @include media("<tablet") { /* is phone */
  }

  @include media(">=tablet", "<desktop") { /* is tablet */
    max-width: calc(30vh);
  }

  @include media(">=desktop", "<xdesktop") { /* is desktop */
    max-width: calc(40vh);
  }

  @include media(">=xdesktop") { /* is xdesktop */
    max-width: calc(50vh);
  }

  //:global(>.l) {
  //  flex: 1 0 33%;
  //  max-width: 33%;
  //
  //  > img {
  //    width: 100%;
  //    height: 100%;
  //
  //    object-fit: contain;
  //    display: block;
  //    border-radius: 8px;
  //
  //    @include media("<tablet") { /* is phone */
  //    }
  //
  //    @include media(">=tablet", "<desktop") { /* is tablet */
  //    }
  //
  //    @include media(">=desktop", "<xdesktop") { /* is desktop */
  //      max-height: calc(50vh);
  //    }
  //
  //    @include media(">=xdesktop") { /* is xdesktop */
  //      max-height: calc(50vh);
  //    }
  //  }
  //}

}
